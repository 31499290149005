import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppModule } from './app.module';

import { AppComponent } from './app.component';

@NgModule({
  imports: [
    AppRoutingModule,
    AppModule,
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppBrowserModule { }
