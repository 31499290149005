<ngx-headroom>


<nav class="navbar">
  <div class="navbar-brand">
    <a class="brand-logo brand-logo__svg" [routerLink]="['']">
      <svg align="left" data-name="Layer 1" height="100%" id="appLogo" preserveAspectRatio="xMinYMin"
        viewBox="0 0 256.1 71.71" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <style>
            .color-1 {
              fill: #e21d31;
            }

            .color-2 {
              fill: #6f6f6f;
            }
          </style>
        </defs>
        <g>
          <g class="brand-logo__icon">
            <path class="color-2"
              d="M24.7,70.57v-38s-4.58-11.13-12.44-3.93S-1.12,41.08.19,50.25,3,69,3,69,13.35,53.06,14.35,47.25c1.24-7.18-.13-19.25,3.15-17.94a9.15,9.15,0,0,1,4.58,3.93V70.57Z">
            </path>
            <path class="color-1"
              d="M29.64,70.57V31.94s.65-15.06,10.48-19,21-6.55,24.23-11.79-3.07,8.48-5.78,16C56,24.32,49.82,26.55,45,27.31c-5.86.92-12.74,4.63-12.74,4.63V70.57Z">
            </path>
          </g>
          <g class="brand-logo__text">
            <path class="color-1"
              d="M99.89,70.57H96.11V57.18a8.63,8.63,0,0,0-8.67-8.7h-24V70.57H59.69V32.09A12.51,12.51,0,0,1,72.16,19.62H87.42A12.51,12.51,0,0,1,99.89,32.09V36A11.8,11.8,0,0,1,94,46.61a11.8,11.8,0,0,1,5.88,10.58ZM96.11,36V32.09a8.64,8.64,0,0,0-8.69-8.69H72.16A8.42,8.42,0,0,0,66,25.93a8.32,8.32,0,0,0-2.57,6.16V44.7h24A8.62,8.62,0,0,0,96.11,36Z">
            </path>
            <path class="color-1" d="M125.64,70.57l-22.7-51h3.78l18.92,42.09,17.5-42.09h3.78Z"></path>
            <path class="color-1" d="M193.83,23.4H175.38V70.57H171.6V23.4H151.95V19.62h41.88Z"></path>
            <path class="color-1"
              d="M237.84,58.1a12.52,12.52,0,0,1-12.47,12.47H210.11A12.51,12.51,0,0,1,197.64,58.1V58l3.78.08a8.64,8.64,0,0,0,8.69,8.69h15.26a8.41,8.41,0,0,0,6.12-2.53,8.33,8.33,0,0,0,2.57-6.16V55.68a8.32,8.32,0,0,0-2.57-6.16A8.42,8.42,0,0,0,225.37,47H210.11a12.51,12.51,0,0,1-12.47-12.47V32.09a12.51,12.51,0,0,1,12.47-12.47h15.26a12.52,12.52,0,0,1,12.47,12.47v.08h-3.78v-.08a8.33,8.33,0,0,0-2.57-6.16,8.43,8.43,0,0,0-6.12-2.53H210.11a8.64,8.64,0,0,0-8.69,8.69v2.42a8.64,8.64,0,0,0,8.69,8.69h15.26a12.52,12.52,0,0,1,12.47,12.47Z">
            </path>
          </g>
          <g class="brand-logo__subline">
            <path class="color-1"
              d="M246.85,36l-.67-.44,1.39-2.23a12.54,12.54,0,0,1-.84-5.31v-.16c0-3.1.53-6.6,4.52-6.6a3.85,3.85,0,0,1,3.08,1.19l1.09-1.75.67.4-1.28,2a9.86,9.86,0,0,1,.95,4.69v.2c0,3.79-.57,7.51-4.56,7.51A3.79,3.79,0,0,1,248,34.12Zm6.62-12.16a2.39,2.39,0,0,0-2.24-1.2h-.16c-2.17,0-2.77,2.39-2.77,5.31v.13a18.61,18.61,0,0,0,.31,3.59Zm-4.56,8.84a2.35,2.35,0,0,0,2.35,1.5c2.52,0,2.92-2.15,2.94-6.11a12.09,12.09,0,0,0-.36-3.32Z">
            </path>
            <path class="color-1"
              d="M252.94,43.39c0-.09,0-.18,0-.26a2.06,2.06,0,0,0-2.19-2.24c-2.21,0-2.44,1.39-2.44,2.44a1.8,1.8,0,0,0,1.29,1.86l2.17.82c2.59,1,3.06,1.88,3.06,3.68,0,2.59-1.62,4.1-4.25,4.1a3.73,3.73,0,0,1-3.74-2.17,7.64,7.64,0,0,1-.29-2.5h1.51v.42c0,1.79.47,2.88,2.59,2.88A2.3,2.3,0,0,0,253.29,50c0-2.08-.84-2.1-2.22-2.63-.75-.29-2-.67-2.68-1.06a3.05,3.05,0,0,1-1.62-3.1,3.59,3.59,0,0,1,3.81-3.74c2.39,0,3.9,1.19,3.9,3.54,0,.11,0,.22,0,.33Z">
            </path>
            <path class="color-1" d="M245.92,58h8.59V59.4H251V71.71h-1.51V59.4h-3.54Z"></path>
          </g>
        </g>
      </svg>
    </a>

    <!-- <div class="navbar-burger burger">
      <button class="burger-icon" role="button" aria-label="menu" aria-expanded="false" data-target="mobileNav" (click)="toggleNav()" [ngClass]="isOpen ? ['is-active'] : []">
        <div class="burger-bar-top" aria-hidden="true"></div>
        <div class="burger-bar-bottom" aria-hidden="true"></div>
      </button>
    </div> -->

    <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="mobileNav" (click)="toggleNav()" [ngClass]="isOpen ? ['is-active'] : []">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>

  </div>

  <div class="navbar-menu">
    <div class="navbar-end">
      <a class="navbar-item" [routerLink]="['/tjenestestotte']" [routerLinkActive]="['active']">Tjenestestøtte</a>
      <a class="navbar-item" [routerLink]="['/verktoy']" [routerLinkActive]="['active']">Digitale ressurser</a>
      <a class="navbar-item" [routerLink]="['/kurs']" [routerLinkActive]="['active']">Kurs</a>
      <a class="navbar-item" [routerLink]="['/aktuelt']" [routerLinkActive]="['active']">Aktuelt</a>

      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link">Fagområder</a>
        <div class="navbar-dropdown">
          <a class="navbar-item" [routerLink]="['/vold-og-overgrep']" [routerLinkActive]="['active']">Vold og overgrep</a>
          <a class="navbar-item" [routerLink]="['/traumer-og-traumatisk-stress']" [routerLinkActive]="['active']">Traumer og traumatisk stress</a>
          <a class="navbar-item" [routerLink]="['/selvmord-og-selvskading']" [routerLinkActive]="['active']">Selvmord og selvskading</a>
          <a class="navbar-item" [routerLink]="['/flyktninger-og-migrasjon']" [routerLinkActive]="['active']">Flyktninger og migrasjon</a>
          <a class="navbar-item" [routerLink]="['/kriser-og-katastrofer']" [routerLinkActive]="['active']">Kriser og katastrofer</a>
          <a class="navbar-item" [routerLink]="['/radikalisering-og-voldelig-ekstremisme']" [routerLinkActive]="['active']">Radikalisering og voldelig ekstremisme</a>
        </div>
      </div>

      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link" [routerLink]="['/om-oss']" [routerLinkActive]="['active']">Om oss</a>
        <div class="navbar-dropdown">
          <a class="navbar-item" [routerLink]="['/medarbeidere']" [routerLinkActive]="['active']">Ansatte</a>
          <a class="navbar-item" [routerLink]="['/strategi']" [routerLinkActive]="['active']">Strategi</a>
          <a class="navbar-item" href="/play-it-right" [routerLinkActive]="['active']">Play It Right</a>
          <!-- <a class="navbar-item" [routerLink]="['/play-it-right']" [routerLinkActive]="['active']">Play It Right</a> -->
        </div>
      </div>

      <a class="button is-primary is-outlined" href="/bestill" [routerLinkActive]="['active']">
        <span class="fas fa-shopping-cart"></span>
        <span>Bestill</span>
      </a>

      <a href="https://old.rvtsost.no/" class="button is-primary is-outlined" target="_blank" rel="noopener">
        <span class="fas fa-id-card-alt"></span>
        <span>Logg inn</span>
      </a>

    </div>

  </div>
</nav>

</ngx-headroom>

<nav class="navbar-menu navbar-mobile is-mobile is-hidden-desktop" id="mobileNav" [ngClass]="isOpen ? ['is-active'] : []">
  <a class="navbar-item" [routerLink]="['/tjenestestotte']" [routerLinkActive]="['active']">Tjenestestøtte</a>
  <a class="navbar-item" [routerLink]="['/verktoy']" [routerLinkActive]="['active']">Digitale ressurser</a>
  <a class="navbar-item" [routerLink]="['/kurs']" [routerLinkActive]="['active']">Kurs</a>
  <a class="navbar-item" [routerLink]="['/aktuelt']" [routerLinkActive]="['active']">Aktuelt</a>
  <a class="navbar-item" [routerLink]="['/vold-og-overgrep']" [routerLinkActive]="['active']">Vold og overgrep</a>
  <a class="navbar-item" [routerLink]="['/traumer-og-traumatisk-stress']" [routerLinkActive]="['active']">Traumer og traumatisk stress</a>
  <a class="navbar-item" [routerLink]="['/selvmord-og-selvskading']" [routerLinkActive]="['active']">Selvmord og selvskading</a>
  <a class="navbar-item" [routerLink]="['/flyktninger-og-migrasjon']" [routerLinkActive]="['active']">Flyktninger og migrasjon</a>
  <a class="navbar-item" [routerLink]="['/kriser-og-katastrofer']" [routerLinkActive]="['active']">Kriser og katastrofer</a>
  <a class="navbar-item" [routerLink]="['/radikalisering-og-voldelig-ekstremisme']" [routerLinkActive]="['active']">Radikalisering og voldelig ekstremisme</a>
  <a class="navbar-item" [routerLink]="['/om-oss']" [routerLinkActive]="['active']">Om oss</a>
  <a class="navbar-item" [routerLink]="['/medarbeidere']" [routerLinkActive]="['active']">Ansatte</a>
  <a class="navbar-item" [routerLink]="['/strategi']" [routerLinkActive]="['active']">Strategi</a>
  <a class="navbar-item" href="/play-it-right" [routerLinkActive]="['active']">Play It Right</a>

  <a class="button is-primary is-outlined" href="/bestill" [routerLinkActive]="['active']">
    <span class="far fa-shopping-cart"></span>
    <span>Bestill</span>
  </a>

  <a href="https://old.rvtsost.no/" class="button is-primary is-outlined" target="_blank" rel="noopener">
    <span class="fas fa-id-card-alt"></span>
    <span>Logg inn</span>
  </a>
</nav>
