import { ModuleWithProviders } from '@angular/core';
import { MetaLoader, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';
export function metaFactory() {
    return new MetaStaticLoader({
        pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
        pageTitleSeparator: ' - ',
        applicationName: 'RVTS Øst',
        defaults: {
            title: 'RVTS Øst',
            description: '',
            'og:type': 'website',
            'og:locale': 'no',
        }
    });
}
const ɵ0 = (metaFactory);
export class CoreModule {
    constructor(parentModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
    static forRoot() {
        return {
            ngModule: CoreModule,
            providers: []
        };
    }
}
export { ɵ0 };
