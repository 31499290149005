<div class="hero-background" [style.backgroundImage]="'url('+ featuredImage +')'" *ngIf="featuredImage">
  <div class="copyright" *ngIf="copyrightImage">&copy; {{copyrightImage}}</div>
</div>

<div class="hero-body" *ngIf="!hero; else heroCustom">
  <div class="container" *ngIf="data && showData">
    <div class="hero-wrapper__inner">
      <h1 class="title is-3"><span>{{data[0].title.rendered}}</span></h1>
      <h2 class="subtitle is-3" *ngIf="data[0] && data[0].acf.sub_title"><span>{{data[0].acf.sub_title}}</span></h2>
    </div>
  </div>
</div>

<ng-template #heroCustom>
  <div class="hero-body" *ngIf="hero">
    <div class="container">
      <div class="hero-wrapper__inner" *ngIf="data">

        <header class="header">
          <h1 [innerHTML]="data[0].acf.title ? data[0].acf.title : data[0].title.rendered  | safe:'html'"
          [ngClass]="['title is-3']" itemprop="headline"></h1>
        </header>
        <div class="" [innerHTML]="data[0].acf.wysiwyg | safe:'html'" [ngClass]="['subtitle is-5']" itemprop="text" *ngIf="data && data[0].acf.wysiwyg"></div>


        <div class="download-badge-wrapper" *ngIf="data && data[0].acf.links">

          <ng-container [ngSwitch]="data[0].acf?.linkStyle">

            <ng-template [ngSwitchCase]="'button'">
              <div class="buttons">
                <ng-container *ngFor="let link of data[0].acf.links" [ngSwitch]="link?.type">

                  <ng-template [ngSwitchCase]="'internal'">
                    <a [routerLink]="link.object.post_name | postType:link.object.post_type" [ngClass]="
                      [
                        'button',
                        data[0].acf.btnColor ? data[0].acf.btnColor : '',
                        data[0].acf.btnStyle ? data[0].acf.btnStyle : ''
                      ]">{{link.text}}</a>
                  </ng-template>

                  <ng-template [ngSwitchCase]="'external'">
                    <a href="{{link.url}}" target="_blank" [ngClass]="
                      [
                        'button',
                        data[0].acf.btnColor ? data[0].acf.btnColor : '',
                        data[0].acf.btnStyle ? data[0].acf.btnStyle : ''
                      ]">{{link.text}}</a>
                  </ng-template>

                </ng-container>

              </div>

            </ng-template>

            <ng-template [ngSwitchCase]="'list'">
              <ul class="">
                <li *ngFor="let link of data[0].acf.links" [ngSwitch]="link?.type">

                  <ng-template [ngSwitchCase]="'internal'">
                    <a [routerLink]="link.object.post_name | postType:link.object.post_type" [ngClass]="
                      [
                        'button-link'
                      ]">{{link.text}}</a>
                  </ng-template>

                  <ng-template [ngSwitchCase]="'external'">
                    <a href="{{link.url}}" target="_blank" [ngClass]="
                      [
                        'button-link'
                      ]">{{link.text}}</a>
                  </ng-template>

                </li>
              </ul>
            </ng-template>

          </ng-container>

        </div>
      </div>
    </div>
  </div>
</ng-template>


