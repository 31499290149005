import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, empty } from 'rxjs';

import { ApiService } from './../_services/api.service';
import { DataService } from './../_services/data.service';
@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  host: { '[class]': 'componentClass' }
})
export class EmployeeComponent implements OnInit, OnDestroy {
  
  paramSubscription: Subscription;

  data: object;

  componentClass: string = 'is-employee';
  slug: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _dataService: DataService,
    private _apiService: ApiService,
    ) {}

  ngOnInit() {
    this.paramSubscription = this._route.paramMap.subscribe(
      params => {
        if (params.get('slug') != 'undefined') {
          this.slug = params.get('slug');
          this._apiService.getData('https://rvtsost.no/api/wp/v2/employee', {
            params: {
              slug: this.slug
            }
          })
          .subscribe((res) => {
            if (res && Object.keys(res).length > 0)
            {
              this._dataService.setData(res)
              this.data = res;
            }
            else {
              return 404;
            }
          })
        }
        else {
          return 404;
        }
      }
    )
  }

  ngOnDestroy() {
    console.log('Component will be destroyed');
    this.paramSubscription.unsubscribe();
    this._dataService.clearData();
  }
}
