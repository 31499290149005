/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./block.component";
import * as i4 from "../_services/api.service";
var styles_BlockComponent = [];
var RenderType_BlockComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BlockComponent, data: {} });
export { RenderType_BlockComponent as RenderType_BlockComponent };
function View_BlockComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["class", "button is-black"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 1), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 2, 0, _co.blockData[0].acf.cta.url); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.blockData[0].acf.cta.title; _ck(_v, 3, 0, currVal_3); }); }
function View_BlockComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "columns is-gapless is-vcentered"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "column has-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "header", [["class", "header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h1", [["class", "title is-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "content has-decoration-top"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BlockComponent_2)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "column has-media"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "figure", [["class", "image is-3by2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.blockData[0].acf.cta.url; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.blockData[0].title.rendered; _ck(_v, 5, 0, currVal_0); var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.blockData[0].better_featured_image.media_details.sizes.content.source_url, ""); var currVal_3 = i0.ɵinlineInterpolate(1, "", _co.blockData[0].better_featured_image.alt_text, ""); _ck(_v, 11, 0, currVal_2, currVal_3); }); }
export function View_BlockComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_BlockComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.blockData; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BlockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-block", [], [[8, "className", 0]], null, null, View_BlockComponent_0, RenderType_BlockComponent)), i0.ɵdid(1, 114688, null, 0, i3.BlockComponent, [i4.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
var BlockComponentNgFactory = i0.ɵccf("app-block", i3.BlockComponent, View_BlockComponent_Host_0, { data: "data" }, {}, []);
export { BlockComponentNgFactory as BlockComponentNgFactory };
