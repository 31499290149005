import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, empty } from 'rxjs';
import { MetaService } from '@ngx-meta/core';

import { ApiService } from './../_services/api.service';
import { DataService } from './../_services/data.service';
@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  host: { '[class]': 'componentClass' }
})
export class PostComponent implements OnInit, OnDestroy {
  
  paramSubscription: Subscription;

  data: object;
  assets: object;

  componentClass: string = 'is-article';
  slug: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private readonly _meta: MetaService,
    private _dataService: DataService,
    private _apiService: ApiService,
    ) {}

  ngOnInit() {
    this.paramSubscription = this._route.paramMap.subscribe(
      params => {
        if (params.get('slug') != 'undefined') {
          this.slug = params.get('slug');
          this._apiService.getData('https://rvtsost.no/api/wp/v2/posts', {
            params: {
              slug: this.slug
            }
          })
          .subscribe((res) => {
            if (res && Object.keys(res).length > 0)
            {
              this._dataService.setData(res)
              this.data = res;

              if ( res[0]['acf'] && res[0]['acf']['assets']['filer'] ||  res[0]['acf']['assets']['web'] ) {
                this.assets = res[0]['acf']['assets'];
              }

              // if ( res[0].type === ( 'post' || 'article' ) ) {
                this._meta.setTag('og:type', 'article');
                this._meta.setTag('article:published_time', res[0].date_gmt);
                this._meta.setTag('article:modified_time', res[0].modified_gmt);
              // }
            }
            else {
              return 404;
            }
          })
        }
        else {
          return 404;
        }
      }
    )
  }

  ngOnDestroy() {
    console.log('Component will be destroyed');
    this.paramSubscription.unsubscribe();
    this._dataService.clearData();
    // this._meta.removeTag('article:published_time');
    // this._meta.removeTag('article:modified_time');
  }
}
