<section class="section is-module module-action-desktop" *ngIf="data">
  <div class="columns is-gapless is-vcentered">
    <div class="column has-content">
      <div class="content-wrapper">
        <header class="header ">
          <h1 class="title is-3">{{data[0].title.rendered}}</h1>
          <h2 class="subtitle is-4" *ngIf="data[0].acf.title">{{data[0].acf.title}}</h2>
        </header>
        <div class="content has-decoration-top">
          <div class="meta">
            <div class="meta-item" *ngIf="data[0].acf.phone">
              <span class="fas fa-mobile-alt fa-fw"></span>
              <a class="button-link" href="tel:{{data[0].acf.phone}}">{{data[0].acf.phone}}</a>
            </div>
            <div class="meta-item" *ngIf="data[0].acf.email">
              <span class="fas fa-envelope fa-fw"></span>
              <a class="button-link" href="mailto:{{data[0].acf.email}}">{{data[0].acf.email}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column has-media">
      <figure class="image is-3by2">
        <img src="{{data[0].better_featured_image.media_details.sizes.content.source_url}}"
          alt="{{data[0].better_featured_image.alt_text}}" />
      </figure>
    </div>
  </div>
</section>

<section class="section" *ngIf="data">
  <div class="container is-960">
    <div class="content" [innerHTML]="data[0].content.rendered | safe:'html'"></div>
    <div class="content" *ngIf="data[0].acf.education">
      <h4 class="title">Utdanning</h4>
      <ul>
        <li class="" *ngFor="let e of data[0].acf.education">
          {{ e.education_year }} {{ e.education_title }}
        </li>
      </ul>
    </div>

    <div class="content" *ngIf="data[0].acf.work">
      <h4>Erfaring</h4>
      <ul class="">
        <li class="" *ngFor="let w of data[0].acf.work">
          {{ w.work_year }} {{ w.work_title }}
        </li>
      </ul>
    </div>
  </div>
</section>