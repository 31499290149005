<div class="container is-xl" *ngIf="data">
  <div class="columns is-multiline">
    <article class="entry column is-3" itemscope itemprop="blogPost" itemtype="https://schema.org/BlogPosting"
      *ngFor="let p of data">
      <a class="card" [routerLink]="['/medarbeidere/' + p.slug]">
        <div class="card-image negative" *ngIf="p.better_featured_image">
          <figure class="image is-3by2">
            <img src="{{p.better_featured_image.media_details.sizes.content.source_url}}"
              alt="{{p.better_featured_image.alt_text}}" />
          </figure>
        </div>
        <header class="card-header has-decoration-left">
          <h2 class="title is-6" itemprop="headline">{{p.title.rendered}}</h2>
          <h3 class="subtitle is-6">{{p.acf.title}}</h3>
        </header>

        <div class="content">
          <div class="meta">
            <div class="meta-item" *ngIf="p.acf.phone">
              <span class="fas fa-mobile-alt fa-fw"></span>
              <a class="button-link" href="tel:{{p.acf.phone}}">{{p.acf.phone}}</a>
            </div>
            <div class="meta-item" *ngIf="p.acf.email">
              <span class="fas fa-envelope fa-fw"></span>
              <a class="button-link" href="tel:{{p.acf.email}}">{{p.acf.email}}</a>
            </div>
          </div>
        </div>
      </a>
    </article>
  </div>
</div>