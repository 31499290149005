import { Component, OnInit } from '@angular/core';

import { Newsletter } from '../_class/newsletter';
import { ApiService } from './../_services/api.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  host: { '[class]': 'componentClass' }
})
export class NewsletterComponent implements OnInit {

  componentClass: string = 'section is-newsletter has-background-light';

  model = new Newsletter();

  disabledAgreement: boolean = true;
  submitted: boolean = false;
  error: {};

  constructor(
    private _apiService: ApiService,
  ) { }
  
  ngOnInit() {
  }

  changeCheck(event){
    this.disabledAgreement = !event.target.checked;
    console.log(this.disabledAgreement);
  }
  
  submitMailchimpForm() {
    this.submitted = true;
    return this.mailChimpSubscribe(this.model);
  }

  mailChimpSubscribe(formData: Newsletter) {
    this._apiService.postData('https://rvtsost.no/api/awww/v1/newsletter', formData)
    .subscribe({
      next: data => this.model = data,
      error: error => console.error('There was an error!', error)
    });
  }

}
