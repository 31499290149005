import { Component, OnInit, OnDestroy, HostListener, Renderer2, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { MetaService } from '@ngx-meta/core';

import { DataService } from './../_services/data.service';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  host: { '[class]': 'componentClass' }
})
export class HeroComponent implements OnInit, OnDestroy {
  
  subscription: Subscription;

  data: Object;
  hero: Object;

  imageService: Array<{sizes: any, meta: any}> = [];
  socialImage: Array<{width: string, height: string, sourceUrl: string}> = [];

  featuredImage: string = '';
  copyrightImage: string = '';

  componentClass: string = 'hero is-light is-default';
  bodyClass: string = '';

  showData: boolean = true;

  deviceInfo = null;
  mobileDevice = null;
  tabletDevice = null;
  desktopDevice = null;

  constructor(
    @Inject(PLATFORM_ID) private platform: Object,
    private renderer: Renderer2,
    private readonly _meta: MetaService,
    private _dataService: DataService,
    private _deviceService: DeviceDetectorService,
  ) {}
  @HostListener('window:resize', ['$event'])
  onresize(event) {
    this.deviceFunction();
  }

  ngOnInit() {
    this.deviceFunction();
    this.subscription = this._dataService._apiData$
    .subscribe((res) => {

      if (isPlatformBrowser(this.platform)) {
        if (this.bodyClass) {
          this.renderer.removeClass(document.body, this.bodyClass);
        }
      }
      this.imageService = [];
      this.socialImage = [];

      this.hero = [];
      this.featuredImage = '';
      this.copyrightImage = '';
      this.showData = true;

      if ( res ) {
        this.data = res;

        if (isPlatformBrowser(this.platform)) {
          this.bodyClass = 'is-'+res[0].type;
          this.renderer.addClass(document.body, this.bodyClass);
        }

        if ( res[0].type != 'page' ) {
          this.showData = false;
        }

        if ( res[0].better_featured_image ) {
          this.socialImage.push({ 
            width: res[0].better_featured_image.media_details.width, 
            height: res[0].better_featured_image.media_details.height, 
            sourceUrl: res[0].better_featured_image.source_url 
          });

          this.imageService.push({
            sizes: res[0].better_featured_image.media_details.sizes,
            meta: res[0].better_featured_image.media_details.image_meta
          });

          if ( this.mobileDevice ) {
            if ( this.imageService[0]['sizes'].featured_mobile ) {
              this.featuredImage = '' +this.imageService[0]['sizes'].featured_mobile.source_url;
            }
          } 
          else {
            if ( this.imageService[0]['sizes'].featured_desktop ) {
              this.featuredImage = this.imageService[0]['sizes'].featured_desktop.source_url;
            } 
            else if ( this.imageService[0]['sizes'].content ) {
              this.featuredImage = this.imageService[0]['sizes'].content.source_url;
            }
            else {
              this.featuredImage = res[0].better_featured_image.source_url;
            }
          }

          if ( this.socialImage ) {
            this._meta.setTag('og:image', 'https://rvtsost.no' +this.socialImage[0]['sourceUrl']);
            this._meta.setTag('og:image:width',  this.socialImage[0]['width']);
            this._meta.setTag('og:image:height', this.socialImage[0]['height']);
          }

          this._meta.setTitle(res[0].title.rendered);
          this._meta.setTag('og:type', 'website');
  
          if ( res[0].excerpt ) {
            this._meta.setTag('og:description', res[0].excerpt.rendered.replace(/<[^>]*>/g, ''));
          }

          if ( this.imageService[0]['meta'].copyright ) {
            this.copyrightImage = this.imageService[0]['meta'].copyright;
          }
          else if ( this.imageService[0]['meta'].credit ) {
            this.copyrightImage = this.imageService[0]['meta'].credit;
          }
        }

        if ( res[0]['acf'].hero ) {
          this.hero = res[0]['acf'].hero;
          console.log(this.hero)

          if ( res[0]['acf'].hero.image ) {
            console.log(res[0]['acf']['hero']['image'])

            this.imageService.unshift({
              sizes: res[0]['acf'].hero.image.sizes,
              meta: {}
            });

            console.log(this.imageService);

          }

          this.imageService = res[0].better_featured_image.media_details.sizes;
          if ( res[0].better_featured_image.media_details.sizes.featured_desktop ) 
          {
            this.featuredImage = res[0].better_featured_image.media_details.sizes.featured_desktop.source_url;
          } 
          else 
          {
            this.featuredImage = res[0].better_featured_image.media_details.sizes.content.source_url;
          }
          if ( this.mobileDevice ) 
          {
            if ( res[0].better_featured_image.media_details.sizes.featured_mobile ) 
            {
              this.featuredImage = res[0].better_featured_image.media_details.sizes.featured_mobile.source_url;
            }
          }

          console.log(this.featuredImage)
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ block: 'start',  behavior: 'smooth' });
  }

  deviceFunction(): void {
    this.deviceInfo = this._deviceService.getDeviceInfo();
    this.mobileDevice = this._deviceService.isMobile();
    this.tabletDevice = this._deviceService.isTablet();
    this.desktopDevice = this._deviceService.isDesktop();
  }

}
