import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { Subscription } from 'rxjs'; 
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-featured',
  templateUrl: './featured.component.html',
  host: { '[class]': 'componentClass' }
})
export class FeaturedComponent implements OnInit, OnDestroy {
  @Input() postData: string;

  componentClass: string = 'is-featured';

  subscription: Subscription;
  data: Object;

  constructor(
    private _apiService: ApiService,
  ) {}

  ngOnInit() {
    this.subscription = this._apiService.getData(environment.domain +'/api/wp/v2/featured', {
      params: {
        include: [this.postData],
      }
    })
    .subscribe((res) => {
      if (res && Object.keys(res).length > 0) {
        this.data = res;

        if (res[0].acf && Object.keys(res[0].acf).length > 0) {
          this.componentClass = 'section is-grid is-featured ' +res[0].acf.color + ' is-' +res[0].acf.featuredPreview +(res[0].acf.color_gradient ? ' ' +res[0].acf.color_gradient : '')
        }

      }
    })
  }

  ngOnDestroy() {
    console.log('Component will be destroyed');
    this.subscription.unsubscribe();
  }
}
