<header class="is-intro" *ngIf="data">
  <h1 class="title is-4">{{!!data ? data[0].title.rendered : ''}}</h1>
  <h2 class="subtitle is-5">{{data[0].acf.sub_title}}</h2>
  <div class="columns is-multiline">
    
    <div class="column">
      <div class="event-date">
        <span class="fas fa-calendar-alt fa-fw"></span>
        <time class="time" *ngIf="data[0].acf.startDate && !data[0].acf.endDate"
          [attr.datetime]="data[0].acf.startDate">{{data[0].acf.startDate | date:'dd.MM.yyyy'}}</time>
        <time class="time" *ngIf="data[0].acf.startDate && data[0].acf.endDate && (data[0].acf.startDate === data[0].acf.endDate)"
          [attr.datetime]="data[0].acf.startDate">{{data[0].acf.startDate | date:'dd.MM.yyyy'}}</time>  
        <time class="time" *ngIf="data[0].acf.startDate && data[0].acf.endDate && (data[0].acf.startDate != data[0].acf.endDate)"
          [attr.datetime]="data[0].acf.startDate">{{(data[0].acf.startDate | date:'MM') === (data[0].acf.endDate | date:'MM') ? (data[0].acf.startDate | date:'dd') : (data[0].acf.startDate | date:'dd.MM')}}.</time>
        <time class="time" *ngIf="data[0].acf.endDate && (data[0].acf.startDate != data[0].acf.endDate)"
          [attr.datetime]="data[0].acf.endDate">-{{data[0].acf.endDate | date:'dd.MM.yyyy'}}</time>
      </div>

      <div class="event-venue" *ngIf="dataVenue">
        <span class="fas fa-map-marker-alt fa-fw"></span>
        <span class="venue-city">{{dataVenue[0].acf.address}}</span>
      </div>
    </div>

    <div class="column event-price">
      <span class="meta-item price-title">Påmeldingsavgift:</span>
      <span class="meta-item price-tag">{{data[0].acf.price ? data[0].acf.price : 'Kurset er gratis'}}</span>
    </div>

    <div class="column is-12 event-enroll" *ngIf="data[0].acf.enroll">
      <a class="button is-black" href="{{data[0].acf.enroll}}" target="_blank" >Påmelding</a>
    </div>
  </div>
</header>

<section class="section" *ngIf="data">
  <header class="section-header is-center">
    <h2 class="title is-2 has-decoration-center">Velkommen til {{data[0].title.rendered}}</h2>
  </header>
  <div class="container is-960">
    <div class="content leader" [innerHTML]="data[0].acf.preamble | safe:'html'"></div>
  </div>
</section>

<section class="section" *ngIf="data && data[0].acf.shortDescription">
  <div class="container is-960">
    <div class="content" [innerHTML]="data[0].acf.shortDescription | safe:'html'"></div>
  </div>
</section>

<section class="section has-background-secondary" *ngIf="data && data[0].acf.audience">
  <div class="columns is-gapless is-vcentered">
    <div class="column has-content">
      <div class="content-wrapper">
        <h2 class="title is-2">Målgruppe</h2>
        <div class="content has-decoration-top" [innerHTML]="data[0].acf.audience | safe:'html'" *ngIf="data[0].acf"></div>
      </div>
    </div>
    <div class="column has-media">
      <figure class="image is-3by2">
        <img src="/app/uploads/sites/5/11-ensom-i-folkemengde-avlang-2160x1440.jpg" />
      </figure>
    </div>
  </div>
</section>

<section class="section" *ngIf="data && data[0].acf.description">
  <div class="container is-960">
    <div class="content" [innerHTML]="data[0].acf.description | safe:'html'"></div>
  </div>
</section>

<section class="section" *ngIf="data && data[0].acf.schedule">
  <header class="section-header is-center">
    <h2 class="title is-2 has-decoration-center">Program</h2>
  </header>
  <div class="container">
    <table class="table is-striped is-hoverable is-fullwidth" *ngFor="let schedule of data[0].acf.schedule">
      <thead *ngIf="schedule.scheduleDay">
        <tr>
          <th colspan="2">{{schedule.scheduleDay}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of schedule.scheduleContent">
          <td>{{item.scheduleTime}}</td>
          <td>{{item.scheduleEvent}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</section>

<section class="section" *ngIf="dataEmployee">
  <div class="container is-xl">
    <header class="section-header is-center">
      <h1 class="title is-1 has-decoration-center">{{data[0]['acf'].employees?.length > 1 ? 'kursansvarlige' : 'kursansvarlig'}}</h1>
    </header>

    <div class="columns is-multiline">
      <article class="entry column is-3" itemscope itemprop="blogPost" itemtype="https://schema.org/BlogPosting" *ngFor="let p of dataEmployee">
        <a class="card" [routerLink]="['/medarbeidere/' + p.slug]">
          <div class="card-image negative" *ngIf="p.better_featured_image">
            <figure class="image is-3by2">
              <img src="{{p.better_featured_image.media_details.sizes.content.source_url}}" alt="{{p.better_featured_image.alt_text}}" />
            </figure>
          </div>
          <header class="card-header has-decoration-left">
            <h2 class="title is-6" itemprop="headline">{{p.title.rendered}}</h2>
            <h3 class="subtitle is-6">{{p.acf.title}}</h3>
          </header>

          <div class="content">
            <div class="meta">
              <div class="meta-item" *ngIf="p.acf.phone">
                <span class="fas fa-mobile-alt fa-fw"></span>
                <a class="button-link" href="tel:{{p.acf.phone}}">{{p.acf.phone}}</a>
              </div>
              <div class="meta-item" *ngIf="p.acf.email">
                <span class="fas fa-envelope fa-fw"></span>
                <a class="button-link" href="tel:{{p.acf.email}}">{{p.acf.email}}</a>
              </div>
            </div>
          </div>
        </a>
      </article>
    </div>
  </div>
</section>

<section class="section is-map" *ngIf="dataVenue">
  <div class="columns is-gapless is-vcentered">
    <div class="column has-content">
      <div class="content-wrapper">
        <header class="header">
          <h1 class="title is-4">{{dataVenue[0].title.rendered}}</h1>
        </header>
        <div class="content has-decoration-top">
          <div class="meta">
            <div class="meta-item">{{dataVenue[0].acf.address}}</div>
            <div class="meta-item">{{dataVenue[0].acf.zip}}, {{dataVenue[0].acf.city}}</div>
          </div>
        </div>
        <div class="meta" *ngIf="dataVenue[0].acf.url">
          <span class="fas fa-globe-europe fa-fw"></span> 
          <a class="button-link" href="{{dataVenue[0].acf.url}}" target="_blank" *ngIf="dataVenue[0].acf.url">
            <span>Besøk nettsiden</span>
          </a>
        </div>
      </div>
    </div>

    <div class="column has-media" *ngIf="dataVenue[0]['acf']['map']">
      <agm-map [latitude]="lat" [longitude]="lng" [draggable]=false [scrollwheel]=null [gestureHandling]="'coopeative'" [styles]="styles" [streetViewControl]="false" [zoom]="14">
        <agm-marker [latitude]="dataVenue[0]['acf']['map']['lat']" [longitude]="dataVenue[0]['acf']['map']['lng']"></agm-marker>
      </agm-map>
    </div>
  </div>
</section>