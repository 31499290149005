<section *ngFor="let module of modules; let i = index" id="{{ 'section_id-' + i }}" class="section" [ngClass]="
  [
    module['acf_fc_layout'] ? 'is-module is-'+module['acf_fc_layout'] : '',
    module['color'] ? 'has-background-'+module.color : '',
    module['margin'] ? 'has-margin'+module.margin : '',
    module['padding'] ? 'has-padding'+module.padding : ''
  ]">


  <div [ngSwitch]="module?.acf_fc_layout" [ngClass]="
  [
    module.column ? module.column : '', 
    module.width ? 'container is-960 is'+module.width : ''
  ]">
    <ng-template [ngSwitchCase]="'editor'">
      <h2 class="title is-3" *ngIf="module.title">{{module.title}}</h2>
      <div [innerHTML]="module.editor | safe:'html'" [ngClass]="['content']"></div>
    </ng-template>

    <ng-template [ngSwitchCase]="'image'">
      bilde
    </ng-template>

    <ng-template [ngSwitchCase]="'block'">
      <app-block [data]="module.featured"></app-block>
    </ng-template>

    <ng-template [ngSwitchCase]="'featured'">
      <app-featured [postData]="module.featured.ID"></app-featured>
    </ng-template>
    
  </div>
</section>

<section class="section" *ngIf="showProjects && dataNews">
  <div class="container is-xl">
    <header class="section-header is-center">
      <h1 class="title is-1 has-decoration-center">Aktuelt & annet</h1>
    </header>

    <div class="columns is-multiline">
      <article class="entry column is-3" itemscope itemprop="blogPost" itemtype="https://schema.org/BlogPosting" *ngFor="let p of dataNews">
        <a class="card" [routerLink]="p.slug | postType:p.type">
          <div class="card-image negative">
            <figure class="image is-3by2">
              <img src="{{p.better_featured_image.media_details.sizes.content ? p.better_featured_image.media_details.sizes.content.source_url : p.better_featured_image.source_url}}"
              alt="{{p.better_featured_image.alt_text}}" *ngIf="p.better_featured_image" />
            </figure>
          </div>
          <header class="card-header">
            <div class="meta has-decoration-left">
              <time class="time" [attr.datetime]="p.date">{{p.date | date:'dd. MMM yyyy':'no'}}</time>
            </div>
            <h2 class="title is-6" itemprop="headline">{{p.title.rendered}}</h2>
          </header>
          <div class="content" [innerHTML]="p.excerpt.rendered | safe:'html'"></div>
        </a>
      </article>
    </div>
  </div>
</section>

<section class="section" *ngIf="showProjects && dataProjects">
  <div class="container is-xl">
    <header class="section-header is-center">
      <h1 class="title is-1 has-decoration-center">Digitale ressurser</h1>
    </header>

    <div class="columns is-multiline">
      <article class="entry column is-3" itemscope itemprop="blogPost" itemtype="https://schema.org/BlogPosting" *ngFor="let p of dataProjects">
        <a class="card" [routerLink]="p.slug | postType:p.type">
          <div class="card-image negative">
            <figure class="image is-3by2">
              <img src="{{p.better_featured_image.media_details.sizes.content ? p.better_featured_image.media_details.sizes.content.source_url : p.better_featured_image.source_url}}"
              alt="{{p.better_featured_image.alt_text}}" *ngIf="p.better_featured_image" />
            </figure>
          </div>
          <header class="card-header has-decoration-left">
            <h2 class="title is-6" itemprop="headline">{{p.title.rendered}}</h2>
          </header>
          <div class="content" [innerHTML]="p.excerpt.rendered" itemprop="text"></div>
        </a>
      </article>
    </div>
  </div>
</section>

<section class="section" *ngIf="showEmployees && dataEmployees">
  <div class="container is-xl">
    <header class="section-header is-center">
      <h1 class="title is-1 has-decoration-center">Spesialrådgivere</h1>
    </header>

    <div class="columns is-multiline">
      <article class="entry column is-3" itemscope itemprop="blogPost" itemtype="https://schema.org/BlogPosting" *ngFor="let p of dataEmployees">
        <a class="card" [routerLink]="p.slug | postType:p.type">
          <div class="card-image negative">
            <figure class="image is-3by2">
              <img src="{{p.better_featured_image.media_details.sizes.content ? p.better_featured_image.media_details.sizes.content.source_url : p.better_featured_image.source_url}}"
              alt="{{p.better_featured_image.alt_text}}" *ngIf="p.better_featured_image" />
            </figure>
          </div>
          <header class="card-header has-decoration-left">
            <h2 class="title is-6" itemprop="headline">{{p.title.rendered}}</h2>
            <h3 class="subtitle is-6">{{p.acf.title}}</h3>
          </header>

          <div class="content">
            <div class="meta">
              <div class="meta-item" *ngIf="p.acf.phone">
                <span class="fas fa-mobile-alt fa-fw"></span>
                <a class="button-link" href="tel:{{p.acf.phone}}">{{p.acf.phone}}</a>
              </div>
              <div class="meta-item" *ngIf="p.acf.email">
                <span class="fas fa-envelope fa-fw"></span>
                <a class="button-link" href="tel:{{p.acf.email}}">{{p.acf.email}}</a>
              </div>
            </div>
          </div>
        </a>
      </article>
    </div>
  </div>
</section>

<app-archive-employee *ngIf="archiveEmployees"></app-archive-employee>
<app-archive-project *ngIf="archiveProjects"></app-archive-project>
<app-archive-event *ngIf="archiveEvents"></app-archive-event>
<app-archive-news *ngIf="archiveNews"></app-archive-news>
<app-contact-form *ngIf="showServices"></app-contact-form>
