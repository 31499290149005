<div class="container is-xl" *ngIf="data">
  <div class="columns is-multiline">
    <article class="entry column is-3" itemscope itemprop="blogPost" itemtype="https://schema.org/BlogPosting" *ngFor="let p of data">
      <a class="card" [routerLink]="['/verktoy/' + p.slug]">
        <div class="card-image negative" *ngIf="p.better_featured_image">
          <figure class="image is-3by2">
            <img src="{{p.better_featured_image.media_details.sizes.content.source_url}}" alt="{{p.better_featured_image.alt_text}}" />
          </figure>
        </div>
        <header class="card-header has-decoration-left">
          <h2 class="title is-6" itemprop="headline">{{p.title.rendered}}</h2>
        </header>
        <div class="content" [innerHTML]="p.excerpt.rendered | safe:'html'"></div>
      </a>
    </article>
  </div>
</div>