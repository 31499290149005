import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, empty } from 'rxjs'; 

import { ApiService } from './../_services/api.service';
import { DataService } from './../_services/data.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  host: { '[class]': 'componentClass' }
})
export class PageComponent implements OnInit, OnDestroy {
  
  paramSubscription: Subscription;
  employeesSubscription: Subscription;
  projectsSubscription: Subscription;
  newsSubscription: Subscription;

  data: object;
  dataEmployees: object;
  dataProjects: object;
  dataNews: object;
  modules: object;

  postData: string;

  componentClass: string;
  slug: string;

  showEmployees: boolean = false;
  showProjects: boolean = false;
  showServices: boolean = false;

  archiveEmployees: boolean = false;
  archiveProjects: boolean = false;
  archiveEvents: boolean = false;
  archiveNews: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _dataService: DataService,
    private _apiService: ApiService,
    ) {}

  ngOnInit() {
    this.paramSubscription = this._route.paramMap.subscribe(
      params => {
        if (params.get('slug') != 'undefined') {
          this.slug = params.get('slug');
          this._apiService.getData('https://rvtsost.no/api/wp/v2/pages', {
            params: {
              slug: this.slug
            }
          })
          .subscribe((res) => {
            if ( res && Object.keys(res).length > 0 )
            {
              this._dataService.setData(res)
              this.data = res;

              if ( res[0]['acf']['modules'] ) {
                this.modules = res[0]['acf']['modules'];
              }

              if ( res[0]['acf']['showEmployees'] ) {
                this.showEmployees = true;
                this.employeesSubscription = this._apiService.getData('https://rvtsost.no/api/wp/v2/employee', {
                  params: {
                    per_page: '100',
                    order: 'asc',
                    field_of_study: res[0]['acf']['departmentEmployees']
                  }
                })
                .subscribe((res) => {
                  if (res && Object.keys(res).length > 0) {
                    this.dataEmployees = res;
                  }
                })
              }

              if ( res[0]['acf']['showProjects'] ) {
                this.showProjects = true;
                this.projectsSubscription = this._apiService.getData('https://rvtsost.no/api/wp/v2/project', {
                  params: {
                    per_page: '100',
                    order: 'asc',
                    field_of_study: res[0]['acf']['departmentProjects']
                  }
                })
                .subscribe((res) => {
                  if (res && Object.keys(res).length > 0) {
                    this.dataProjects = res;
                  }
                })


                this.newsSubscription = this._apiService.getData('https://rvtsost.no/api/wp/v2/posts', {
                  params: {
                    per_page: '100',
                    order: 'desc',
                    orderby: 'date',
                    field_of_study: res[0]['acf']['departmentProjects']
                  }
                })
                .subscribe((res) => {
                  if (res && Object.keys(res).length > 0) {
                    this.dataNews = res;
                  }
                })
              }

              if ( res[0]['acf']['showServices'] ) {
                this.showServices = true;
              }

              if ( res[0]['acf']['archiveEmployees'] ) {
                this.archiveEmployees = true;
              }

              if ( res[0]['acf']['archiveProjects'] ) {
                this.archiveProjects = true;
              }

              if ( res[0]['acf']['archiveEvents'] ) {
                this.archiveEvents = true;
              }

              if ( res[0]['acf']['archiveNews'] ) {
                this.archiveNews = true;
              }


            }
            else {
              return 404;
            }
          })
        }
        else {
          return 404;
        }
      }
    )
  }

  ngOnDestroy() {
    console.log('Component will be destroyed');
    this.paramSubscription.unsubscribe();
    this._dataService.clearData();
  }
}
