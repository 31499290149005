import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  host: { '[class]': 'componentClass' }
})
export class AssetsComponent {  

  @Input() data: [];

  componentClass: string = 'section is-assets has-background-light';

  constructor() {
    console.log(this.data);
  }


  ngOninit() {
    console.log(this.data);
  }


}
