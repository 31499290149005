/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../_directives/external-link.directive";
import * as i2 from "@angular/common";
import * as i3 from "./assets.component";
var styles_AssetsComponent = [];
var RenderType_AssetsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AssetsComponent, data: {} });
export { RenderType_AssetsComponent as RenderType_AssetsComponent };
function View_AssetsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["ref", ""], ["target", "_blank"]], [[1, "rel", 0], [1, "target", 0], [1, "href", 4]], null, null, null, null)), i0.ɵdid(2, 540672, null, 0, i1.ExternalLinkDirective, [i0.PLATFORM_ID], { href: [0, "href"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "fas fa-globe-europe fa-fw"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var currVal_3 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.file, ""); _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).relAttr; var currVal_1 = i0.ɵnov(_v, 2).targetAttr; var currVal_2 = i0.ɵnov(_v, 2).hrefAttr; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_4); }); }
function View_AssetsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AssetsComponent_2)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data["filer"]; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AssetsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["ref", ""], ["target", "_blank"]], [[1, "rel", 0], [1, "target", 0], [1, "href", 4]], null, null, null, null)), i0.ɵdid(2, 540672, null, 0, i1.ExternalLinkDirective, [i0.PLATFORM_ID], { href: [0, "href"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "fas fa-globe-europe fa-fw"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var currVal_3 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.url, ""); _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).relAttr; var currVal_1 = i0.ɵnov(_v, 2).targetAttr; var currVal_2 = i0.ɵnov(_v, 2).hrefAttr; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_4); }); }
function View_AssetsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AssetsComponent_4)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data["web"]; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AssetsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "container is-960"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "header", [["class", "section-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h2", [["class", "title is-2 has-decoration-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Ressurser"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AssetsComponent_1)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AssetsComponent_3)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.data && _co.data["filer"]); _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.data && _co.data["web"]); _ck(_v, 7, 0, currVal_1); }, null); }
export function View_AssetsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-assets", [], [[8, "className", 0]], null, null, View_AssetsComponent_0, RenderType_AssetsComponent)), i0.ɵdid(1, 49152, null, 0, i3.AssetsComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
var AssetsComponentNgFactory = i0.ɵccf("app-assets", i3.AssetsComponent, View_AssetsComponent_Host_0, { data: "data" }, {}, []);
export { AssetsComponentNgFactory as AssetsComponentNgFactory };
