import { Component, OnInit, OnDestroy } from '@angular/core';
import { PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser} from '@angular/common';
import { MetaService } from '@ngx-meta/core';

import { ApiService } from './../../shared/_services/api.service';
import { DataService } from './../../shared/_services/data.service';
import { Subscription } from 'rxjs'; 

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  host: { '[class]': 'componentClass' }
})
export class HomeComponent implements OnInit, OnDestroy {

  page: Subscription;
  news: Subscription;
  events: Subscription;

  data: object;
  blocks: object;

  newsData: object;
  eventsData: object;

  componentClass: string = 'is-home';
  currentDate: string;

  browser: boolean;

  featuredId: string = '1337';

  constructor(
    private readonly _meta: MetaService,
    private _dataService: DataService,
    private _apiService: ApiService,
    @Inject(PLATFORM_ID) private platform: Object
  ) {}

  ngOnInit() {
    this.currentDate = new Date(Date.now()).toLocaleString();
    this.page = this._apiService.getData('https://rvtsost.no/api/wp/v2/pages', {
      params: {
        slug: 'rvts-ost',
      }
    })
    .subscribe((res) => {
      if (res) {
        this._dataService.setData(res)
        this.data = res;

        if ( res[0]['acf'] && res[0]['acf']['content_block'] ) {
          this.blocks = res[0]['acf']['content_block'];
        }

        this._meta.setTitle(res[0].title.rendered);
        this._meta.setTag('og:type', 'website');

        if ( res[0].excerpt ) {
          this._meta.setTag('og:description', res[0].excerpt.rendered.replace(/<[^>]*>/g, ''));
        }
      }

      this.news = this._apiService.getData('https://rvtsost.no/api/wp/v2/posts', {
        params: {
          per_page: '6',
          categories_exclude: [34],
          order: 'desc',
          orderby: 'date'
        }
      })
      .subscribe((res) => {
        if (res) {
          this.newsData = res;
        }
      })

      this.events = this._apiService.getData('https://rvtsost.no/api/wp/v2/event', {
        params: {
          per_page: '4',
          order: 'desc',
          meta_key: 'startDate',
          meta_value: this.currentDate,
          meta_compare: '=>',
          order_by: 'startDate'        
        }
      })
      .subscribe((res) => {
        if (res) {
          this.eventsData = res;
        }
      })
    })

    if (isPlatformBrowser(this.platform)) {
      this.browser = true;
    }
  }

  ngOnDestroy() {
    console.log('Component will be destroyed');
    this.page.unsubscribe();
    this.news.unsubscribe();
    this.events.unsubscribe();
    this._dataService.clearData()
  }
}
