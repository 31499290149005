<section class="section">
  <div class="container is-960">
    <header class="section-header is-center">
      <h1 class="title is-2">{{!!data ? data[0].title.rendered : ''}}</h1>
    </header>

    <div class="entry content" [innerHTML]="data[0]['content']['rendered'] | safe:'html'" *ngIf="data && data[0]['content']"></div>
  </div>

  <footer class="content-footer" *ngIf="data">
    <div class="container is-960">
      <nav class="nav-social">
        <a href="https://www.facebook.com/sharer/sharer.php?u={{data[0]['link']}}" target="_blank"><span
            aria-hidden="true" class="fab fa-facebook-square"></span></a>
        <a href="http://www.twitter.com/share?url={{data[0]['link']}}" target="_blank"><span aria-hidden="true"
            class="fab fa-twitter-square"></span></a>
        <a href="https://www.linkedin.com/shareArticle?mini=true&url={{data[0]['link']}}" target="_blank"><span
            aria-hidden="true" class="fab fa-linkedin"></span></a>
      </nav>
    </div>
  </footer>
</section>