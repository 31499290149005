/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "../_directives/external-link.directive";
import * as i4 from "../_pipes/safe.pipe";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../_pipes/post-type.pipe";
import * as i7 from "./hero.component";
import * as i8 from "@ngx-meta/core";
import * as i9 from "../_services/data.service";
import * as i10 from "ngx-device-detector";
var styles_HeroComponent = [];
var RenderType_HeroComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeroComponent, data: {} });
export { RenderType_HeroComponent as RenderType_HeroComponent };
function View_HeroComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "copyright"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\u00A9 ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.copyrightImage; _ck(_v, 1, 0, currVal_0); }); }
function View_HeroComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "hero-background"]], [[4, "backgroundImage", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.copyrightImage; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.featuredImage) + ")"); _ck(_v, 0, 0, currVal_0); }); }
function View_HeroComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h2", [["class", "subtitle is-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data[0].acf.sub_title; _ck(_v, 2, 0, currVal_0); }); }
function View_HeroComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "hero-wrapper__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "h1", [["class", "title is-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_5)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.data[0] && _co.data[0].acf.sub_title); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data[0].title.rendered; _ck(_v, 4, 0, currVal_0); }); }
function View_HeroComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "hero-body"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_4)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.data && _co.showData); _ck(_v, 2, 0, currVal_0); }, null); }
function View_HeroComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", ""], ["itemprop", "text"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(3, 1), i0.ɵppd(4, 2)], function (_ck, _v) { var currVal_1 = ""; var currVal_2 = _ck(_v, 3, 0, "subtitle is-5"); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent.parent.parent, 0), _co.data[0].acf.wysiwyg, "html")); _ck(_v, 0, 0, currVal_0); }); }
function View_HeroComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(3, 3), i0.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵppd(5, 2), (_l()(), i0.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, "button", (_co.data[0].acf.btnColor ? _co.data[0].acf.btnColor : ""), (_co.data[0].acf.btnStyle ? _co.data[0].acf.btnStyle : "")); _ck(_v, 2, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent.parent.parent.parent.parent.parent.parent, 1), _v.parent.context.$implicit.object.post_name, _v.parent.context.$implicit.object.post_type)); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).target; var currVal_1 = i0.ɵnov(_v, 4).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _v.parent.context.$implicit.text; _ck(_v, 6, 0, currVal_4); }); }
function View_HeroComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["target", "_blank"]], [[1, "rel", 0], [1, "target", 0], [1, "href", 4]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(3, 3), i0.ɵdid(4, 540672, null, 0, i3.ExternalLinkDirective, [i0.PLATFORM_ID], { href: [0, "href"] }, null), (_l()(), i0.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 3, 0, "button", (_co.data[0].acf.btnColor ? _co.data[0].acf.btnColor : ""), (_co.data[0].acf.btnStyle ? _co.data[0].acf.btnStyle : "")); _ck(_v, 2, 0, currVal_3); var currVal_4 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.url, ""); _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).relAttr; var currVal_1 = i0.ɵnov(_v, 4).targetAttr; var currVal_2 = i0.ɵnov(_v, 4).hrefAttr; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _v.parent.context.$implicit.text; _ck(_v, 5, 0, currVal_5); }); }
function View_HeroComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_13)), i0.ɵdid(3, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_14)), i0.ɵdid(5, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.type); _ck(_v, 1, 0, currVal_0); var currVal_1 = "internal"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "external"; _ck(_v, 5, 0, currVal_2); }, null); }
function View_HeroComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_12)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data[0].acf.links; _ck(_v, 2, 0, currVal_0); }, null); }
function View_HeroComponent_17(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(3, 1), i0.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵppd(5, 2), (_l()(), i0.ɵted(6, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _ck(_v, 3, 0, "button-link"); _ck(_v, 2, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent.parent.parent.parent.parent.parent.parent, 1), _v.parent.context.$implicit.object.post_name, _v.parent.context.$implicit.object.post_type)); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).target; var currVal_1 = i0.ɵnov(_v, 4).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _v.parent.context.$implicit.text; _ck(_v, 6, 0, currVal_4); }); }
function View_HeroComponent_18(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["target", "_blank"]], [[1, "rel", 0], [1, "target", 0], [1, "href", 4]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(3, 1), i0.ɵdid(4, 540672, null, 0, i3.ExternalLinkDirective, [i0.PLATFORM_ID], { href: [0, "href"] }, null), (_l()(), i0.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_3 = _ck(_v, 3, 0, "button-link"); _ck(_v, 2, 0, currVal_3); var currVal_4 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.url, ""); _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).relAttr; var currVal_1 = i0.ɵnov(_v, 4).targetAttr; var currVal_2 = i0.ɵnov(_v, 4).hrefAttr; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _v.parent.context.$implicit.text; _ck(_v, 5, 0, currVal_5); }); }
function View_HeroComponent_16(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "li", [], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_17)), i0.ɵdid(3, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_18)), i0.ɵdid(5, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.type); _ck(_v, 1, 0, currVal_0); var currVal_1 = "internal"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "external"; _ck(_v, 5, 0, currVal_2); }, null); }
function View_HeroComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_16)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data[0].acf.links; _ck(_v, 2, 0, currVal_0); }, null); }
function View_HeroComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "download-badge-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, null, null, null, null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i1.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_11)), i0.ɵdid(4, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_15)), i0.ɵdid(6, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data[0].acf == null) ? null : _co.data[0].acf.linkStyle); _ck(_v, 2, 0, currVal_0); var currVal_1 = "button"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "list"; _ck(_v, 6, 0, currVal_2); }, null); }
function View_HeroComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "hero-wrapper__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "header", [["class", "header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "h1", [["itemprop", "headline"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(5, 1), i0.ɵppd(6, 2), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_9)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_10)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 5, 0, "title is-3"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.data && _co.data[0].acf.wysiwyg); _ck(_v, 8, 0, currVal_2); var currVal_3 = (_co.data && _co.data[0].acf.links); _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.data[0].acf.title ? _co.data[0].acf.title : i0.ɵunv(_v, 2, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent.parent.parent, 0), _co.data[0].title.rendered, "html"))); _ck(_v, 2, 0, currVal_0); }); }
function View_HeroComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "hero-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_8)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 3, 0, currVal_0); }, null); }
function View_HeroComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_7)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hero; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HeroComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i4.SafePipe, [i5.DomSanitizer]), i0.ɵpid(0, i6.PostTypePipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["heroCustom", 2]], null, 0, null, View_HeroComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.featuredImage; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.hero; var currVal_2 = i0.ɵnov(_v, 6); _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
export function View_HeroComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-hero", [], [[8, "className", 0]], [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onresize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_HeroComponent_0, RenderType_HeroComponent)), i0.ɵdid(1, 245760, null, 0, i7.HeroComponent, [i0.PLATFORM_ID, i0.Renderer2, i8.MetaService, i9.DataService, i10.DeviceDetectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
var HeroComponentNgFactory = i0.ɵccf("app-hero", i7.HeroComponent, View_HeroComponent_Host_0, {}, {}, []);
export { HeroComponentNgFactory as HeroComponentNgFactory };
