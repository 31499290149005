<div class="columns is-gapless is-vcentered">


  <div class="column has-content">
    <div class="content-wrapper">
      <header class="header">
        <h1 class="title is-3">Ta kontakt for mer informasjon</h1>
      </header>
      <div class="content has-decoration-top">
        <div class="meta">
          <div class="meta-item">
            <span class="fas fa-phone fa-fw"></span>
            <a class="button-link" href="tel:22586000">22 58 60 00</a>
          </div>
          <div class="meta-item">
            <span class="fas fa-envelope fa-fw"></span>
            <a class="button-link" href="mailto:post@rvtsost.no">post@rvtsost.no</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="column">
    <form class="is-form" #emailForm="ngForm" (ngSubmit)="submitEmailForm()">

    <div class="columns is-multiline">
      <div class="column is-6">
        <div class="field">
          <div class="control has-icons-left">
            <input class="input" type="text" placeholder="Fornavn" name="firstName" aria-required="true"
              aria-invalid="false" [(ngModel)]="model.firstName" required #firstName="ngModel">
            <span class="icon is-small is-left">
              <span class="fas fa fa-user"></span>
            </span>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <div class="control has-icons-left">
            <input class="input" type="text" placeholder="Etternavn" name="lastName" aria-required="true"
              aria-invalid="false" [(ngModel)]="model.lastName" required #lastName="ngModel">
            <span class="icon is-small is-left">
              <span class="fas fa fa-user"></span>
            </span>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <div class="control has-icons-left">
            <input pattern="[0-9]" class="input" type="text" placeholder="Telefon" name="phone"
              aria-required="true" aria-invalid="false" [(ngModel)]="model.phone" required #phone="ngModel">
            <span class="icon is-small is-left">
              <span class="fas fa-phone"></span>
            </span>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <div class="control has-icons-left">
            <input pattern="\S+@\S+\.\S+" class="input" type="email" placeholder="E-postadresse" name="email"
              aria-required="true" aria-invalid="false" [(ngModel)]="model.email" required email #email="ngModel">
            <span class="icon is-small is-left">
              <span class="fas fa-envelope"></span>
            </span>
          </div>
        </div>
      </div>

      <div class="column is-12">
        <div class="field">
          <div class="control has-icons-left">
            <input type="text" name="company" value="" class="input" id="first_name" aria-required="true" aria-invalid="false" placeholder="Arbeidssted" [(ngModel)]="model.company">
            <span class="icon is-small is-left">
              <span class="fas fa-envelope"></span>
            </span>
          </div>
        </div>
      </div>

      <div class="column is-12">
        <textarea name="message" cols="40" rows="10" class="textarea" id="message" aria-required="true" aria-invalid="false" placeholder="Beskjed" [(ngModel)]="model.message" required #message="ngModel"></textarea>
      </div>

      <div class="column is-12">
        <div class="field">
          <label class="checkbox" for="agreeEmail">
            <input type="checkbox" (change)="changeCheck($event)" id="agreeEmail">
            <span>Jeg godtar at mine personlige data blir lagret slik at RVTS Øst kan behandle og besvare min henvendelse så effektivt og korrekt som mulig.</span>
          </label>
          <p>Les vår <a class="button-link" [routerLink]="['/personvern']"
              [routerLinkActive]="['active']">personvernerklæring</a> for å se hvordan vi behandler og benytter din
            informasjon.</p>
        </div>
      </div>
      <div class="column is-4">
        <div class=" field">
          <button class="button is-black" [disabled]="disabledAgreement">Meld meg på</button>
        </div>
      </div>

    </div>

    </form>

  </div>

</div>