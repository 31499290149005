import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, empty } from 'rxjs'; 

import { ApiService } from './../../shared/_services/api.service';
import { DataService } from './../../shared/_services/data.service';

import { Contact } from './../../shared/_class/contact';

@Component({
  selector: 'app-tjeneste',
  templateUrl: './tjeneste.component.html',
  styleUrls: ['./tjeneste.component.scss']
})
export class TjenesteComponent implements OnInit, OnDestroy {

  model = new Contact();

  disabledAgreement: boolean = true;
  submitted: boolean = false;
  error: {};


  page: Subscription;

  data: object;

  componentClass: string;
  slug: string = 'tjenestestotte';

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _dataService: DataService,
    private _apiService: ApiService,
    ) {}


  ngOnInit() {
    this.page = this._apiService.getData('https://rvtsost.no/api/wp/v2/pages', {
      params: {
        slug: this.slug,
      }
    })
    .subscribe((res) => {
      if (res) {
        this._dataService.setData(res)
        this.data = res;
      }
    }  
  )}

  changeCheck(event){
    this.disabledAgreement = !event.target.checked;
    console.log(this.disabledAgreement);
  }

  submitEmailForm() {
    this.submitted = true;
  }


  ngOnDestroy() {
    console.log('Component will be destroyed');
    this.page.unsubscribe();
    this._dataService.clearData();
  }

}
