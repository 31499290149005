<section class="section is-module module-action" *ngIf="data">
  <div class="container">
    <div class="columns">

      <div class="column card card-action" *ngFor="let featured of data[0]['acf']['featured']; let i = index"
        [ngClass]="{'card-featured': i===1}">
        <a [routerLink]="['/' + featured.url.post_name]">
          <header class="card-header">
            <span [ngClass]="featured.icon" aria-hidden="true"></span>
            <h1 class="card-header-title" [innerHTML]="featured.title | safe:'html'"></h1>
            <svg class="card-header-waves" enable-background="new 0 0 300 100" height="100px" id="Layer_1"
              preserveAspectRatio="none" version="1.1" viewBox="0 0 300 100" width="300px" x="0px" xml:space="preserve"
              xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" y="0px">
              <path class="waves waves-layer-1" d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
  c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" fill="#FFFFFF" opacity="0.6"></path>
              <path class="waves waves-layer-2" d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
  c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" fill="#FFFFFF" opacity="0.6"></path>
              <path class="waves waves-layer-3" d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
  H42.401L43.415,98.342z" fill="#FFFFFF" opacity="0.7"></path>
              <path class="waves waves-layer-4" d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
  c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z" fill="#FFFFFF"></path>
            </svg>
          </header>
          <div class="card-entry">
            <p class="lead">{{featured.lead}}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>

<section class="section" *ngIf="newsData">
  <header class="section-header is-center">
    <h1 class="title is-1 has-decoration-center">Aktuelt & annet</h1>
  </header>
  <div class="container">
    <div class="columns is-multiline">
      <article class="entry column is-6" itemscope itemprop="blogPost" itemtype="https://schema.org/BlogPosting" *ngFor="let post of newsData">
        <a [routerLink]="['/aktuelt/' + post.slug]">
          <header>
            <div class="meta has-decoration-left">
              <time class="time" [attr.datetime]="post.date">{{post.date | date:'dd MMM yyyy':'nb'}}</time>
            </div>
            <h2 class="title is-6" itemprop="headline">{{post.title.rendered}}</h2>
          </header>
          <div class="content" [innerHTML]="post.excerpt.rendered" itemprop="text"></div>
        </a>
      </article>

      <div class="column is-12">
        <a [routerLink]="['/aktuelt']" class="button is-black">Aktuelt</a>
      </div>
    </div>
  </div>
</section>


<ng-container [ngSwitch]="block?.acf_fc_layout" *ngFor="let block of blocks">

  <ng-template [ngSwitchCase]="'featured'">
    <app-featured [postData]="block.featured.ID"></app-featured>
  </ng-template>

</ng-container>


<section class="section" *ngIf="eventsData">
  <header class="section-header is-center">
    <h1 class="title is-1 has-decoration-center">Kurs & Konferanser</h1>
  </header>
  <div class="container is-xl">
    <div class="columns">
      <article class="entry column" itemscope itemprop="blogPost" itemtype="https://schema.org/BlogPosting" *ngFor="let post of eventsData">
        <a class="card" [routerLink]="['/kurs/' + post.slug]">
          <div class="card-image negative" *ngIf="post.better_featured_image">
            <figure class="image is-3by2">
              <img src="{{post.better_featured_image.media_details.sizes.content.source_url}}" alt="{{post.better_featured_image.alt_text}}" />
            </figure>
          </div>
          <header class="card-header has-decoration-left">
            <h2 class="title is-6" itemprop="headline">{{post.title.rendered}}</h2>
          </header>
          <div class="card-meta">
            <time class="time" *ngIf="post.acf.startDate && !post.acf.endDate" [attr.datetime]="post.acf.startDate">{{post.acf.startDate | date:'dd.MM yyyy'}}</time>
            <time class="time" *ngIf="post.acf.startDate && post.acf.endDate && (post.acf.startDate === post.acf.endDate)" [attr.datetime]="post.acf.startDate">{{post.acf.startDate | date:'dd.MM yyyy'}}</time>
            <time class="time" *ngIf="post.acf.startDate && post.acf.endDate && (post.acf.startDate != post.acf.endDate)" [attr.datetime]="post.acf.startDate && post.acf.endDate">{{(post.acf.startDate | date:'MM') === (post.acf.endDate | date:'MM') ? (post.acf.startDate | date:'dd') : (post.acf.startDate | date:'dd.MM')}}.</time>
            <time class="time" *ngIf="post.acf.endDate && (post.acf.startDate != post.acf.endDate)" [attr.datetime]="post.acf.endDate">-{{post.acf.endDate | date:'dd.MM yyyy'}}</time>
          </div>
        </a>
      </article>
    </div>
  </div>
</section>

<app-newsletter></app-newsletter>
