import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, empty } from 'rxjs';

import { ApiService } from './../_services/api.service';
import { DataService } from './../_services/data.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  host: { '[class]': 'componentClass' }
})
export class EventComponent implements OnInit, OnDestroy {
  
  paramSubscription: Subscription;

  data: object;
  dataVenue: object;
  dataEmployee: object;

  componentClass: string;
  slug: string;


  lat: number = parseFloat('59.949600');
  lng: number = parseFloat('10.768340');

  styles: any[] = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ];  

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _dataService: DataService,
    private _apiService: ApiService,
    ) {}

  ngOnInit() {
    this.paramSubscription = this._route.paramMap.subscribe(
      params => {
        if (params.get('slug') != 'undefined') {
          this.slug = params.get('slug');
          this._apiService.getData('https://rvtsost.no/api/wp/v2/event', {
            params: {
              slug: this.slug
            }
          })
          .subscribe((res) => {
            if (res && Object.keys(res).length > 0)
            {
              this._dataService.setData(res)
              this.data = res;

              if ( res[0]['acf']['venue'] ) {
                this._apiService.getData('https://rvtsost.no/api/wp/v2/venue/', {
                  params: {
                    include: [res[0]['acf']['venue']],
                  }
                })
                .subscribe((res) => {
                  this.dataVenue = res;

                  this.lat = parseFloat(res[0]['acf']['map']['lat']);
                  this.lng = parseFloat(res[0]['acf']['map']['lng']);
                })
              }

              if ( res[0]['acf']['employees']) {
                // let num = res[0]['acf']['employees'];
                
                // num.forEach(function (value) {
                //   console.log('employeeID, ' +value);
                // });
  
                this._apiService.getData('https://rvtsost.no/api/wp/v2/employee', {
                  params: {
                    include: [res[0]['acf']['employees']],
                    order: 'asc',
                    orderby: 'title',
                  },
                })
                .subscribe((res) => {
                  this.dataEmployee = res;  
                })
              }
            }
            else {
              return 404;
            }
          })
        }
        else {
          return 404;
        }
      }
    )
  }

  ngOnDestroy() {
    console.log('Component will be destroyed');
    this.paramSubscription.unsubscribe();
    this._dataService.clearData();
  }
}
