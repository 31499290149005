import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotFoundComponent } from './core/not-found/not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { PageComponent } from './shared/page/page.component';
import { ArticleComponent } from './shared/article/article.component';
import { EventComponent } from './shared/event/event.component';
import { PostComponent } from './shared/post/post.component';
import { ProjectComponent } from './shared/project/project.component';
import { EmployeeComponent } from './shared/employee/employee.component';
import { TjenesteComponent } from './pages/tjeneste/tjeneste.component';


const routes: Routes = [ 
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'tjeneste',
    component: TjenesteComponent
  },
  {
    path: ':slug',
    component: PageComponent
  },
  {
    path: 'aktuelt/:slug',
    component: PostComponent
  },
  {
    path: 'kurs/:slug',
    component: EventComponent
  },
  {
    path: 'verktoy/:slug',
    component: ProjectComponent
  },
  {
    path: 'artikkel/:slug',
    component: ArticleComponent
  },
  {
    path: 'medarbeidere/:slug',
    component: EmployeeComponent
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/not-found'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
