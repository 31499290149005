<div *ngIf="blockData" class="columns is-gapless is-vcentered">
  <div class="column has-content">
    <div class="content-wrapper">
      <header class="header">
        <h1 class="title is-2">{{blockData[0].title.rendered}}</h1>
      </header>
      <!-- <h2 class="subtitle">{{blockData[0].acf.cta.title}}</h2> -->
      <div class="content has-decoration-top">
      </div>
      <a class="button is-black" [routerLink]="[blockData[0].acf.cta.url]" *ngIf="blockData[0].acf.cta.url">{{blockData[0].acf.cta.title}}</a>
    </div>
  </div>
  <div class="column has-media">
    <figure class="image is-3by2">
      <img src="{{blockData[0].better_featured_image.media_details.sizes.content.source_url}}" alt="{{blockData[0].better_featured_image.alt_text}}" />
    </figure>
  </div>
</div>