import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';

import { SafePipe } from './_pipes/safe.pipe';
import { CategoryPipe } from './_pipes/category.pipe';
import { StringDatePipe } from './_pipes/string-date.pipe';
import { CategoryFilterPipe } from './_pipes/category-filter.pipe';

import { HeroComponent } from './hero/hero.component';
import { AssetsComponent } from './assets/assets.component';
import { PageComponent } from './page/page.component';
import { ArticleComponent } from './article/article.component';
import { PostComponent } from './post/post.component';
import { ProjectComponent } from './project/project.component';
import { EventComponent } from './event/event.component';
import { ExternalLinkDirective } from './_directives/external-link.directive';
import { EmployeeComponent } from './employee/employee.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { ArchiveProjectComponent } from './archive/archive-project/archive-project.component';
import { ArchiveEmployeeComponent } from './archive/archive-employee/archive-employee.component';
import { ArchiveNewsComponent } from './archive/archive-news/archive-news.component';
import { ArchiveEventComponent } from './archive/archive-event/archive-event.component';
import { BlockComponent } from './block/block.component';
import { PostTypePipe } from './_pipes/post-type.pipe';
import { CollaboratorsComponent } from './collaborators/collaborators.component';
import { FeaturedComponent } from './featured/featured.component';



@NgModule({
  declarations: [
    SafePipe,
    CategoryPipe,
    StringDatePipe,
    CategoryFilterPipe,
    HeroComponent,
    AssetsComponent,
    PageComponent,
    ArticleComponent,
    EventComponent,
    PostComponent,
    ProjectComponent,
    ExternalLinkDirective,
    EmployeeComponent,
    ContactFormComponent,
    NewsletterComponent,
    ArchiveProjectComponent,
    ArchiveEmployeeComponent,
    ArchiveNewsComponent,
    ArchiveEventComponent,
    BlockComponent,
    PostTypePipe,
    CollaboratorsComponent,
    FeaturedComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAk_aPGO341GF6yNrxrjB-yk0XywZB0_L4'
    })
    
  ],
  exports: [
    SafePipe,
    CategoryPipe,
    StringDatePipe,
    CategoryFilterPipe,
    PostTypePipe,
    HeroComponent,
    AssetsComponent,
    ContactFormComponent,
    NewsletterComponent,
    BlockComponent,
    CollaboratorsComponent,
    FeaturedComponent
  ]
})
export class SharedModule { }
