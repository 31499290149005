<header class="is-intro" *ngIf="data">
  <h1 class="title is-4">{{!!data ? data[0].title.rendered : ''}}</h1>
  <h2 class="subtitle is-5">{{data[0].acf.sub_title}}</h2>
  <div class="columns is-multiline">
    <div class="column columns is-multiline is-12 is-tag" [innerHTML]="data[0].field_of_study | categoryFilter:categories | safe:'html'" *ngIf="data[0].field_of_study"></div>
    <div class="column is-12 event-enroll" *ngIf="data[0].acf.url">
      <a class="button is-black" href="{{data[0].acf.url}}" target="_blank" >Besøk nettstedet</a>
    </div>
  </div>
</header>

<section *ngFor="let module of modules; let i = index" id="{{ 'section_id-' + i }}" class="section" [ngClass]="
  [
    module['acf_fc_layout'] ? 'is-module is-'+module['acf_fc_layout'] : '',
    module['color'] ? 'has-background-'+module.color : '',
    module['margin'] ? 'has-margin'+module.margin : '',
    module['padding'] ? 'has-padding'+module.padding : ''
  ]">


  <div [ngSwitch]="module?.acf_fc_layout" class="container" [ngClass]="
  [
    module.column ? module.column : '', 
    module.width ? 'is-960 is'+module.width : ''
  ]">
    <ng-template [ngSwitchCase]="'editor'">
      <h2 class="title is-3" *ngIf="module.title">{{module.title}}</h2>
      <div [innerHTML]="module.editor | safe:'html'" [ngClass]="['content']"></div>
    </ng-template>

    <ng-template [ngSwitchCase]="'image'">
      bilde
    </ng-template>
    
  </div>
</section>