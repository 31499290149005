<div class="columns is-gapless is-vcentered">
  <div class="column has-content">
    <div class="content-wrapper">
      <header class="header">
        <h1 class="title is-3">Motta siste nytt fra RVTS Øst?</h1>
      </header>
      <div class="content has-decoration-top">
        <p>Vårt nyhetsbrev gir informasjon om kommende kurs, konferanser, kompetanseutviklingsprogrammer eller andre
          nyheter om RVTS Østs tilbud og tjenester.</p>
      </div>
    </div>
  </div>
  <div class="column">
    <form class="is-form" #mailChimpForm="ngForm" (ngSubmit)="submitMailchimpForm()">
      <div class="columns is-multiline">
        <div class="column is-6">
          <div class="field">
            <div class="control has-icons-left">
              <input class="input" type="text" placeholder="Fornavn" name="firstName"
                aria-required="true" aria-invalid="false" [(ngModel)]="model.firstName" required #firstName="ngModel">
              <span class="icon is-small is-left">
                <span class="fas fa-envelope"></span>
              </span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <div class="control has-icons-left">
              <input class="input" type="text" placeholder="Etternavn" name="lastName"
                aria-required="true" aria-invalid="false" [(ngModel)]="model.lastName" required #lastName="ngModel">
              <span class="icon is-small is-left">
                <span class="fas fa-envelope"></span>
              </span>
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div class="field">
            <div class="control has-icons-left">
              <input pattern="\S+@\S+\.\S+" class="input" type="email" placeholder="E-postadresse" name="email"
                aria-required="true" aria-invalid="false" [(ngModel)]="model.email" required email #email="ngModel">
              <span class="icon is-small is-left">
                <span class="fas fa-envelope"></span>
              </span>
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div class="field">
            <label class="checkbox" for="agree">
              <input type="checkbox" (change)="changeCheck($event)" id="agree">
              <span>Jeg godtar at mine personlige data blir lagret slik at jeg kan motta nyhetsbrev fra RVTS Øst.</span>
            </label>
            <p>Les vår <a class="button-link" [routerLink]="['/personvern']" [routerLinkActive]="['active']">personvernerklæring</a> for å se hvordan vi behandler og benytter din informasjon.</p>
          </div>
        </div>
        <div class="column is-4">
          <div class=" field">
            <button class="button is-black"
              [disabled]="disabledAgreement">Meld meg på</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>