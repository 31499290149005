/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../../_pipes/safe.pipe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./archive-project.component";
import * as i6 from "../../_services/api.service";
var styles_ArchiveProjectComponent = [];
var RenderType_ArchiveProjectComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ArchiveProjectComponent, data: {} });
export { RenderType_ArchiveProjectComponent as RenderType_ArchiveProjectComponent };
function View_ArchiveProjectComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "card-image negative"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "figure", [["class", "image is-3by2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.better_featured_image.media_details.sizes.content.source_url, ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.better_featured_image.alt_text, ""); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_ArchiveProjectComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "article", [["class", "entry column is-3"], ["itemprop", "blogPost"], ["itemscope", ""], ["itemtype", "https://schema.org/BlogPosting"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "a", [["class", "card"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(3, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ArchiveProjectComponent_3)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 2, "header", [["class", "card-header has-decoration-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h2", [["class", "title is-6"], ["itemprop", "headline"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "content"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(10, 2)], function (_ck, _v) { var currVal_2 = _ck(_v, 3, 0, ("/verktoy/" + _v.context.$implicit.slug)); _ck(_v, 2, 0, currVal_2); var currVal_3 = _v.context.$implicit.better_featured_image; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.title.rendered; _ck(_v, 8, 0, currVal_4); var currVal_5 = i0.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i0.ɵnov(_v.parent.parent, 0), _v.context.$implicit.excerpt.rendered, "html")); _ck(_v, 9, 0, currVal_5); }); }
function View_ArchiveProjectComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "container is-xl"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "columns is-multiline"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ArchiveProjectComponent_2)), i0.ɵdid(3, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ArchiveProjectComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.SafePipe, [i4.DomSanitizer]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ArchiveProjectComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ArchiveProjectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-archive-project", [], [[8, "className", 0]], null, null, View_ArchiveProjectComponent_0, RenderType_ArchiveProjectComponent)), i0.ɵdid(1, 245760, null, 0, i5.ArchiveProjectComponent, [i1.ActivatedRoute, i1.Router, i6.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
var ArchiveProjectComponentNgFactory = i0.ɵccf("app-archive-project", i5.ArchiveProjectComponent, View_ArchiveProjectComponent_Host_0, {}, {}, []);
export { ArchiveProjectComponentNgFactory as ArchiveProjectComponentNgFactory };
