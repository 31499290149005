import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'categoryFilter'
})
@Injectable()
export class CategoryFilterPipe implements PipeTransform {
  /**
   * @param items object from array
   * @param term term's search
   */
  transform(value, args): any {
    if (!value || !args) return value;
    return CategoryFilterPipe.filter(value, args);
  }

  /**
   *
   * @param items List of items to filter
   * @param term  a string term to compare with every property of the list
   *
   */
  static filter(value, args): any {
    let output = '';

    value.forEach(function(number){
      output =  output +'<span class="column tag is-light">'+args[number] +'</span>';
    })
    return output;
  }
}
