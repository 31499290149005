import { Component, OnInit } from '@angular/core';

import { Contact } from '../_class/contact';
import { ApiService } from './../_services/api.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  host: { '[class]': 'componentClass' }
})
export class ContactFormComponent implements OnInit {

  componentClass: string = 'section is-contact-form has-background-light';

  model = new Contact();

  disabledAgreement: boolean = true;
  submitted: boolean = false;
  error: {};

  constructor(
    private _apiService: ApiService,
  ) { }
  
  ngOnInit() {
  }

  changeCheck(event){
    this.disabledAgreement = !event.target.checked;
    console.log(this.disabledAgreement);
  }
  
  submitEmailForm() {
    this.submitted = true;
    return this.sendEmail(this.model);
  }

  sendEmail(formData: Contact) {
    this._apiService.postData('https://rvtsost.no/api/awww/v1/email', formData)
    .subscribe({
      next: data => this.model = data,
      error: error => console.error('There was an error!', error)
    });
  }

}
