import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule, PLATFORM_ID, APP_ID, Inject, LOCALE_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { TransferHttpCacheModule } from '@nguniversal/common';

import { AppRoutingModule } from './app-routing.module';
import { RouteReuseStrategy } from '@angular/router';
import { AARouteReuseStrategy } from './shared/_class/aaroute-reuse-strategy';

import { AppComponent } from './app.component';

import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { PagesModule } from './pages/pages.module';

import { BrowserStateInterceptor } from './shared/_interceptor/browserstate.interceptor';
import { from } from 'rxjs';

import { registerLocaleData, DatePipe } from '@angular/common';
import localeNb from '@angular/common/locales/nb'; 
registerLocaleData(localeNb, 'nb'); 


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({
      appId: 'serverApp'
    }),
    TransferHttpCacheModule,
    BrowserTransferStateModule,
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    NgtUniversalModule,
    CoreModule.forRoot(),
    SharedModule,
    PagesModule,
  ],
  exports: [
    AppRoutingModule
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: BrowserStateInterceptor,
    //   multi: true
    // },
    {
      provide: RouteReuseStrategy,
      useClass: AARouteReuseStrategy,
    },
    DatePipe,
    {
      provide: LOCALE_ID,
      useValue: 'nb'
    },
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
