<!-- <div class="container" *ngIf="data">
  <div class="columns is-multiline">
    <article class="entry column is-4" itemscope itemprop="blogPost" itemtype="https://schema.org/BlogPosting"
      *ngFor="let p of data">
      <a [routerLink]="['/aktuelt/' + p.slug]">
        <header>
          <div class="meta has-decoration-left">
            <time class="time" [attr.datetime]="p.date">{{p.date | date:'dd. MMM yyyy':'nb'}}</time>
          </div>
          <h2 class="title is-6" itemprop="headline">{{p.title.rendered}}</h2>
        </header>
        <div class="content" [innerHTML]="p.excerpt.rendered" itemprop="text"></div>
      </a>
    </article>
  </div>
</div> -->

<div class="container is-xl" *ngIf="data">
  <div class="columns is-multiline">
    <article class="entry column is-3" itemscope itemprop="blogPost" itemtype="https://schema.org/BlogPosting"
      *ngFor="let p of data">
      <a class="card" [routerLink]="['/aktuelt/' + p.slug]">
        <div class="card-image negative">
          <figure class="image is-3by2">
            <img src="{{p.better_featured_image.media_details.sizes.content ? p.better_featured_image.media_details.sizes.content.source_url : p.better_featured_image.source_url}}"
              alt="{{p.better_featured_image.alt_text}}" *ngIf="p.better_featured_image" />
          </figure>
        </div>
        <header class="card-header">
          <div class="meta has-decoration-left">
            <time class="time" [attr.datetime]="p.date">{{p.date | date:'dd. MMM yyyy':'no'}}</time>
          </div>
          <h2 class="title is-6" itemprop="headline">{{p.title.rendered}}</h2>
        </header>
        <div class="content" [innerHTML]="p.excerpt.rendered" itemprop="text"></div>
      </a>
    </article>
  </div>
</div>