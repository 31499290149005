<div class="container is-xl" *ngIf="data">
  <div class="columns is-multiline">
    <article class="entry column is-3" itemscope itemprop="blogp" itemtype="https://schema.org/Blogping"
      *ngFor="let p of data">
      <a class="card" [routerLink]="['/kurs/' + p.slug]">
        <div class="card-image negative" *ngIf="p.better_featured_image">
          <figure class="image is-3by2">
            <img src="{{p.better_featured_image.media_details.sizes.content.source_url}}"
              alt="{{p.better_featured_image.alt_text}}" />
          </figure>
        </div>
        <header class="card-header has-decoration-left">
          <h2 class="title is-6" itemprop="headline">{{p.title.rendered}}</h2>
        </header>
        <div class="card-meta">
          <time class="time" *ngIf="p.acf.startDate && !p.acf.endDate"
            [attr.datetime]="p.acf.startDate">{{p.acf.startDate | date:'dd.MM yyyy'}}</time>
          <time class="time" *ngIf="p.acf.startDate && p.acf.endDate && (p.acf.startDate === p.acf.endDate)"
            [attr.datetime]="p.acf.startDate">{{p.acf.startDate | date:'dd.MM yyyy'}}</time>
          <time class="time" *ngIf="p.acf.startDate && p.acf.endDate && (p.acf.startDate != p.acf.endDate)"
            [attr.datetime]="p.acf.startDate && p.acf.endDate">{{(p.acf.startDate | date:'MM') === (p.acf.endDate | date:'MM') ? (p.acf.startDate | date:'dd') : (p.acf.startDate | date:'dd.MM')}}.</time>
          <time class="time" *ngIf="p.acf.endDate && (p.acf.startDate != p.acf.endDate)"
            [attr.datetime]="p.acf.endDate">-{{p.acf.endDate | date:'dd.MM yyyy'}}</time>
        </div>
      </a>
    </article>
  </div>
</div>