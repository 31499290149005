<div class="container is-960">
  <header class="section-header">
    <h2 class="title is-2 has-decoration-left">Ressurser</h2>
  </header>
  
  <ul *ngIf="data && data['filer']">
    <li *ngFor="let n of data['filer']">
      <a href="{{n.file}}" target="_blank" ref="">
        <span class="fas fa-globe-europe fa-fw"></span>
        {{n.title}}
      </a>
    </li>
  </ul>

  <ul *ngIf="data && data['web']">
    <li *ngFor="let n of data['web']">
      <a href="{{n.url}}" target="_blank" ref="">
        <span class="fas fa-globe-europe fa-fw"></span>
        {{n.title}}
      </a>
    </li>
  </ul>

</div>