import { Component, OnInit, Input } from '@angular/core';
import { Subscription, empty } from 'rxjs';

import { ApiService } from './../_services/api.service';

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  host: { '[class]': 'componentClass' }
})
export class BlockComponent implements OnInit {
  @Input() data: [];

  paramSubscription: Subscription;
  blockData: object;
  componentClass: string = '';

  constructor(
    private _apiService: ApiService,
  ) {}


  ngOnInit() {
    this.paramSubscription = this._apiService.getData('https://rvtsost.no/api/wp/v2/featured', {
      params: {
        include: [this.data]
      }
    })
    .subscribe((res) => {
      if (res && Object.keys(res).length > 0) {
        this.blockData = res;

        console.log(this.blockData)
      } else {
        return 404;
      }
    })
  }

}
