export class Contact {
  firstName: string;
  lastName :string;
  email: string;
  phone?: string;
  company?: string;
  postNumber?: string;
  postArea?: string;
  message?: string;
}
