
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, empty } from 'rxjs'; 

import { ApiService } from './../../_services/api.service';
// import { DataService } from './../../_services/data.service';

@Component({
  selector: 'app-archive-project',
  templateUrl: './archive-project.component.html',
  host: { '[class]': 'componentClass' }
})
export class ArchiveProjectComponent implements OnInit, OnDestroy {
  
  paramSubscription: Subscription;

  data: object;

  componentClass: string = 'section is-archive';
  currentDate: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    // private _dataService: DataService,
    private _apiService: ApiService,
    ) {}

  ngOnInit() {
    this.currentDate = new Date(Date.now()).toLocaleString();
    this.paramSubscription = this._apiService.getData('https://rvtsost.no/api/wp/v2/project', {
      params: {
        per_page: '100',
        order: 'desc',
        orderby: 'date'         
      }
    })
    .subscribe((res) => {
      if (res && Object.keys(res).length > 0) {
        this.data = res;
      }
    })
  }

  ngOnDestroy() {
    console.log('Component will be destroyed');
    this.paramSubscription.unsubscribe();
  }

}
