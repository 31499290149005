import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private options: any;
  private httpOptions: any = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  constructor(
    private _http: HttpClient
  ) {}


  getData(data: string, options?: any): Observable<Object> {
    return this._http.get(data, options? options : false)
  }


  postData(data: string, options?: any, httpOptions?: any): Observable<any> {
    return this._http.post(data, options? options : false, this.httpOptions)
  }
}
